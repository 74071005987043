import React, { useContext, useState } from 'react'
import ModeContext from '../context/mode/modeContext'

const TextBox = ({showAlert}) => {

    const {mode} = useContext(ModeContext)

    const [text,setText] = useState('')
    const readTime = 0.008 * text.split(/\s+/).length

    const uppercaseHandler = ()=>{
        setText(text.toUpperCase())
        showAlert('Text converted to uppercase','success')
    }
    
    const lowercaseHandler = ()=>{
        setText(text.toLowerCase())
        showAlert('Text converted to lowercase','warning')
    }

  return (
    <>
    <div className={`pageWrapper pt-5 mode-${mode}`}>
        <div className="container">

            <h2>Enter the text to anyalze below</h2>
            <textarea className='form-control' rows="5" value={text} onChange={e=>setText(e.target.value)}></textarea>
            
            <div className="mt-3">
                <button className='btn btn-primary' onClick={uppercaseHandler}>Convert to Uppercase</button>
                <button className='btn btn-primary ms-2' onClick={lowercaseHandler}>Convert to Lowercase</button>
            </div>

            <div className='mt-4'>
                <h2>Summary</h2>
                <div>
                    <b> { text.length > 0 ? text.split(/\s+/).length : 0 } words and {text.length} characters</b>
                    <p> <b> {readTime == '0.008' ? '0' : readTime. toFixed(2)} Minutes read</b> </p>
                </div>

                <h4>Preview</h4>
                <p> {text? text : 'Write something in the above textbox to preview  here'} </p>
            </div>
        </div>
    </div>
    </>
  )
}

export default TextBox