import React, { useContext, useEffect } from 'react'
import NoteContext from '../context/notes/noteContext'

const Alerts = ({alert, alertHandle}) => {

const context = useContext(NoteContext)

  
  useEffect(()=>{
    alertHandle(context.alert.type,context.alert.message) 
  },[context.alert])
  

  return (
    <>{ alert &&
        <div className={`alert alert-${alert.type} alert-dismissible fixed-top fade show`} role="alert">
            <strong style={{textTransform:'capitalize'}}> {alert.type == 'danger' ? 'Error' : alert.type} </strong>
            {alert.msg}
        </div>
     }
    </>
  )
}

export default Alerts