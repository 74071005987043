import logo from './logo.svg';
import './App.css';
import { Navbar, TextBox, NotesPage, Alerts} from './Components';
import { useState } from 'react';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import NoteState from './context/notes/noteState';
import ModeState from './context/mode/modeState';

function App() {
  
  const [alert,setAlert] = useState(null)

  const alertHandle = (type,msg) => {
    setAlert({type:type, msg:msg})
    setTimeout(()=>{
      setAlert(null)
    },1500)
  }

  return (
    <>
    <ModeState>
      <NoteState>
        <BrowserRouter>

          <Navbar  title='myNotes'/>
          <Alerts alert={alert} alertHandle={alertHandle}/>
      
            <Routes>
              <Route path='/' element={<TextBox/>}/>
              <Route path='/notes' element={<NotesPage/>}/>
            </Routes>

        </BrowserRouter>
      </NoteState>
    </ModeState>
    </>
  );
}

export default App;


{/*
Utils wala code


  const [alert,setAlert] = useState(null)
  const [mode,setMode] = useState('light')
  const [theme,setTheme] = useState('primary')

  const showAlert = (message, type)  => {
    setAlert({
      msg:message,
      type:type
    })
    setTimeout(()=> {
      setAlert(null)
    },2000)
  }

  const switchTheme = themeColor => {
    setTheme(themeColor)
  }

  return (
    <>
    <div className={`wrapper theme-${mode}`}>
        <Navbar title='Text Utilities' mode={mode} setMode={setMode} theme={theme} themeHandler={switchTheme}/>
        <Alerts alert={alert}/>
        <div className="container my-5">
          <TextBox showAlert={showAlert} theme={theme} />
        </div>
      </div>
      
    </>
  );
*/}