import { useContext } from "react"
import {AddNotes, EditModal, Notes} from "."
import ModeContext from "../context/mode/modeContext"

const NotesPage = () => {

  const {mode} = useContext(ModeContext)

  return (
    <div className={`pageWrapper mode-${mode}`}>
      <div className="container">
          <AddNotes/>
          <EditModal/>
          <Notes/>
      </div>
    </div>
  )
}

export default NotesPage