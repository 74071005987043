import {useEffect, useState } from "react"
import { initailNotes } from "../../constants/data"
import NoteContext from "./noteContext"


const NoteState = ({children}) => {

  let notesInitials = JSON.parse(localStorage.getItem('notes'))
  if(!notesInitials) notesInitials = initailNotes

  // const notesInitials = initailNotes

    const [notes, setNotes] = useState(notesInitials)
    const [editNote, setEditNote] = useState({id:0, title:0, body:0})
    const [alert, setAlert] = useState([])
    
    // const getNotes = [{id:1,name:'Abhi'}, {id:2, name:'Anu'}]
   
    useEffect(()=>{
      localStorage.setItem('notes',JSON.stringify(notes))
    },[notes])
    
    const showError = error => {
      setAlert({id:0, type:'danger', message:error})
    } 

    // add a note
    const addNote = (note) => {
      let id
      
      if(!notes.length == 0){
        const lastId = JSON.parse(localStorage.getItem('notes')).sort((a,b)=>{
          if(a.id > b.id ) return -1
          if(a.id < b.id) return 1
          else return 0
        })
        
        id = lastId[0].id+1
      }
      else {
        id = 1
      }

      note = {
        "userId": 1,
        "id": id,
        "title": note.title,
        "body": note.body
      }
      setNotes(notes.concat(note))
      setAlert({id, type:'success', message:'Note added successfully'})
    }


    // delete a note
    const deleteNoteHandle = id => {
      const newNotes = notes.filter(note => { return note.id != id})     
      setNotes(newNotes)

      setAlert({id, type:'success', message:'Note deleted successfully'})
    }

    // edit a note
    const editNoteModal = id => {
      const editNote = notes.findIndex(note=> note.id === id)
      const {title, body} = notes[editNote]
      setEditNote({id:id, title:title, body:body})
    }

    // save edited note
    const saveEditedNote = () => {
      // console.log(editNote)
      const index = notes.findIndex(note => note.id === editNote.id)
      const newNotes = notes.filter(note=> note.id != editNote.id)
      const appendEditedNote = newNotes.splice(index,0, {userId:1, id:editNote.id, title:editNote.title, body:editNote.body} )
      setNotes(newNotes)
      // setNotes(notes.splice(index,1, {user:1, id:editNote.id, title:editNote.title, body:editNote.body}))

      setAlert({id:editNote.id, type:'success', message:'Note edited successfully'})
    }



  return (
    <NoteContext.Provider value={{notes, setNotes, addNote, deleteNoteHandle, editNoteModal, editNote ,setEditNote,  saveEditedNote , alert, showError}}>
        {children}
    </NoteContext.Provider>
  )
}

export default NoteState