import React, { useContext } from 'react'
import NoteContext from '../context/notes/noteContext'

const Noteitem = ({note}) => {
    const {id, title, body} = note
    const context = useContext(NoteContext)
    const {deleteNoteHandle, editNoteModal} = context

  return (
    <>
    <div className="col-md-3 mb-4">
      <div className="card">
      <span className="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-danger">{id}</span>
        <div className="card-body">
          <h6 className="card-title d-flex">
            <small>{title}</small>
            <i className="bi bi-pencil ms-auto btn p-0"
               onClick={()=>editNoteModal(id)}
               data-bs-toggle="modal" data-bs-target="#editNote"
               >  
            </i>
            <i className="bi bi-trash ms-2 btn p-0" onClick={()=>deleteNoteHandle(id)}></i>
          </h6>
          <p className="card-text small"><small>{body}</small></p>
        </div>
      </div>
    </div>
    </>
  )
}

export default Noteitem