import React, { useContext } from 'react'
import NoteContext from '../context/notes/noteContext'

const EditModal = () => {

    const context = useContext(NoteContext)
    const {editNote, setEditNote, saveEditedNote} = context

    const handleSaveNote = () => {
        saveEditedNote(editNote.id)
    }

    const onChange = e => {
        setEditNote({...editNote, [e.target.name]:e.target.value})
    }

    return (
        <div className="modal fade" id="editNote" aria-labelledby="editNote" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header ">
                        <h1 className="modal-title fs-5" id="editNote">Edit Note - {editNote.id}</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <label htmlFor="editTitle">Title</label>
                        <input type="text" id='editTitle' name='title' className='form-control mb-4' value={editNote.title} onChange={e=> onChange(e)}/>
                        <label htmlFor="editBody">Body</label>
                        <textarea id='editBody' name="body" rows="3" value={editNote.body} onChange={e=> onChange(e)} className='form-control'></textarea>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-warning" data-bs-dismiss="modal" onClick={handleSaveNote}>Save changes</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditModal