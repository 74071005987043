import { useContext } from 'react';
import { Link, NavLink} from 'react-router-dom';
import ModeContext from '../context/mode/modeContext'

const Navbar = ({title}) => {

    const context = useContext(ModeContext)
    const {mode, setMode} = context

    const swithMode = () =>{
        if(mode=='dark'){
            setMode('light')
        }else{
            setMode('dark')
        }
    }

    return (
        <nav className={`navbar navbar-${mode} navbar-expand-lg bg-${mode} text-${mode=='light' ? 'dark' : 'light'}`}>
            <div className="container-fluid">
                <Link className='navbar-brand' to="/">
                    {title}
                </Link>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon" />
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <NavLink to='' className="nav-link" end>
                                Home
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to='/notes' className="nav-link">
                                Notes
                            </NavLink>
                        </li>
                    </ul>

                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox" role="switch" id="mode"
                            onClick={swithMode} style={{cursor:'pointer'}}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="mode"
                            style={{cursor:'pointer', userSelect:'none'}}
                        >
                            Enable {mode=='light' ? 'dark' : 'light'} mode
                        </label>
                    </div>
                    
                </div>
            </div>
        </nav>

    )
}

export default Navbar