import React, { useContext } from 'react'
import NoteContext from '../context/notes/noteContext'
import Noteitem from './Noteitem'

const Notes = () => {

    const context = useContext(NoteContext)

    const {notes,addNote} = context


  return (
        <section className="container py-5">
            <h1>Show Notes -</h1>
            <div className="row mt-4">
                {
                notes.map(note=>
                    <Noteitem key={note.id} note={note}/>
                )
                }
            </div>
        </section>
  )
}

export default Notes