import { useContext, useState } from "react"
import NoteContext from "../context/notes/noteContext"


const AddNotes = () => {

    const context = useContext(NoteContext)
    const {addNote, showError} = context
    const [note, setNote] = useState({title:'', body:''})

    const handleAddNote = () => {
      if(!note.title || !note.body) {
        showError('Title or Description can not be empty')
      }else{
        addNote(note)
        setNote({title:'', body:''})
      }
    }

    const onChange = e => {
        setNote({...note, [e.target.name] : e.target.value })
    }

  return (
    <section className="py-5">
        <h1>Add Notes</h1>
        <input type="text" placeholder="Title" className="form-control mb-3" name='title' onChange={e=>onChange(e)} value={note.title}/>
        <textarea placeholder="Description"  className="form-control mb-3" rows="3" name='body'  value={note.body} onChange={e=>onChange(e)}></textarea>
        <button className="btn btn-primary" onClick={()=> handleAddNote() }>Add Item</button>
    </section>
  )
}

export default AddNotes