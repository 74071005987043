import {useEffect, useState } from "react"
import ModeContext from "./modeContext"


const ModeState = ({children}) => {
  
  const [mode, setMode] = useState('light')

  return (
    <ModeContext.Provider value={{mode, setMode}}>
        {children}
    </ModeContext.Provider>
  )
}

export default ModeState